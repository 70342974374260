
.slider-thumb::before,
.slider-thumb::after {
    content: "";
    position: absolute;
    top: 20%;
    width: 450px;
    height: 450px;
    background: #17141d;
    border-radius: 62% 47% 82% 35% / 45% 45% 80% 66%;
    will-change: border-radius, transform, opacity;
    display: block;
    z-index: -1;
}

.slider-thumb::before {
    left: 0;
    animation: sliderShape 5s linear infinite;
}

.slider-thumb::after {
    right: 1.5%;
    animation: sliderShape 5s linear infinite reverse;
}

@keyframes sliderShape {
    0%, 100% {
        border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
        transform: translate3d(0, 0, 20px) rotateZ(0.01deg);
    }
    34% {
        border-radius: 70% 30% 46% 54% / 30% 29% 71% 70%;
        transform: translate3d(0, 25px, 0) rotateZ(0.01deg);
    }
    50% {
        transform: translate3d(20px, 0, 0) rotateZ(0.01deg);
    }
    67% {
        border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
        transform: translate3d(0, -3px, 0) rotateZ(0.01deg);
    }
}
